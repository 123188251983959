<template>
  <b-card>
    <h2>{{ item.attributes.libelle }}</h2>
    <div class="mt-3" v-for="item in items" :key="item.id">
      <StrapiQuestionComponent :item="item"></StrapiQuestionComponent>
    </div>
  </b-card>
</template>

<script>
import StrapiQuestionComponent from "./StrapiQuestionComponent.vue";
import StrapiService from "../../services/strapi.service";
import UtilsService from "../../services/utils.service";
export default {
  name: "StrapiCategorieQuestionComponent",
  props: ["item"],
  components: {
    StrapiQuestionComponent,
  },
  data() {
    return {
      items: [],
    };
  },
  mounted() {
    return StrapiService.getQuestions(this.item.id).then((response) => {
      this.items = response.data.data.sort(
        UtilsService.sortByNumberProperty("attributes.ordre")
      );
    });
  },
};
</script>

<style scoped></style>
