<template>
  <b-container fluid>
    <h2>Ev&egrave;nements &agrave; venir</h2>
    <ContentSliderComponent :items="aVenir" :noLoop="true">
      <template v-slot="{ item }">
        <StrapiEvenementCard :item="item"></StrapiEvenementCard>
      </template>
    </ContentSliderComponent>
    <h2>Ev&egrave;nements pass&eacute;s</h2>
    <ContentSliderComponent :items="passes" :noLoop="true">
      <template v-slot="{ item }">
        <b-overlay
          :show="true"
          variant="white"
          opacity="0.30"
          role="button"
          @click="$router.push({ name: 'evenement', params: { id: item.id } })"
        >
          <template #overlay>&nbsp;</template>
          <StrapiEvenementCard :item="item"></StrapiEvenementCard>
        </b-overlay>
      </template>
    </ContentSliderComponent>
  </b-container>
</template>

<script>
import ContentSliderComponent from "../controls/ContentSliderComponent.vue";
import StrapiEvenementCard from "./StrapiEvenementCard.vue";
import UtilsService, { SortDirection } from "../../services/utils.service";
export default {
  name: "StrapiAgendaComponent",
  props: ["items"],
  components: {
    ContentSliderComponent,
    StrapiEvenementCard,
  },
  computed: {
    today() {
      return UtilsService.dateToIsoSqlDate(new Date());
    },
    aVenir() {
      return this.items
        ?.filter(
          (i) => (i.attributes.dateFin ?? i.attributes.dateDebut) >= this.today
        )
        .sort(
          UtilsService.sortBy(
            UtilsService.sortByStringProperty("attributes.dateDebut"),
            UtilsService.sortByStringProperty("attributes.dateFin")
          )
        );
    },
    passes() {
      return this.items
        ?.filter(
          (i) => (i.attributes.dateFin ?? i.attributes.dateDebut) < this.today
        )
        .sort(
          UtilsService.sortBy(
            UtilsService.sortByStringProperty(
              "attributes.dateDebut",
              SortDirection.DESC
            ),
            UtilsService.sortByStringProperty(
              "attributes.dateFin",
              SortDirection.DESC
            )
          )
        );
    },
  },
};
</script>
