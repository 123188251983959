<template>
  <section>
    <div v-b-toggle="`reponse_${item.id}`">
      <QuestionBloc>
        <b-row>
          <b-col>
            <strong>{{ item.attributes.question }}</strong>
          </b-col>
          <b-col cols="auto">
            <span class="when-open">
              <b-icon-chevron-up></b-icon-chevron-up>
            </span>
            <span class="when-closed">
              <b-icon-chevron-down></b-icon-chevron-down>
            </span>
          </b-col>
        </b-row>
      </QuestionBloc>
    </div>
    <b-collapse :id="`reponse_${item.id}`">
      <div class="ml-5">
        <StrapiMarkdownComponent :item="item.attributes.reponse">
        </StrapiMarkdownComponent>
      </div>
      <hr />
    </b-collapse>
  </section>
</template>

<script>
import QuestionBloc from "../blocs/QuestionBloc.vue";
import StrapiMarkdownComponent from "./StrapiMarkdownComponent.vue";
export default {
  name: "StrapiQuestionComponent",
  props: ["item"],
  components: { QuestionBloc, StrapiMarkdownComponent },
};
</script>
