// Fonctions réutilisables pour les composants incluant des ValidationProvider

/**
 * Getter pour interpréter la prop "disabled"
 */
const computedDisabled = function () {
  return this.disabled === true || this.disabled === "true";
};

/**
 * Getter pour interpréter la prop "labelCols"
 * 
 * Renvoie un objet au format { <breakpoint>: <label-cols>, ... }
 */
const computedLabelCols = function () {
  // Attention, typeof null === "object" !
  // Cf. https://developer.mozilla.org/fr/docs/Web/JavaScript/Reference/Operators/typeof#typeof_null
  if (this.labelCols && typeof this.labelCols === "object") {
    return this.labelCols;
  }
  return { xs: this.labelCols };
};

/**
 * Méthode utilisée pour convertir et vérifier les données numériques
 * 
 * La valeur fournie par l'input peut être de type string ou number
 */
const sanitizeNumberValue = function (value) {
  // Vérifier le type de donnée
  if (value == null || value === "") {
    return null;
  }

  // Forcer la conversion en numérique
  if (typeof value === "string") {
    value = parseFloat(value);
  }

  // Vérifier que la valeur est correcte
  if (
    value === Number.NaN ||
    value <= -Number.MAX_VALUE ||
    value >= Number.MAX_VALUE
  ) {
    return null;
  }

  return value;
};

const computed = {
  computedDisabled,
  computedLabelCols,
};

const methods = {
  sanitizeNumberValue,
};

export { computed, methods };