<template>
  <section v-if="items.length > 0" class="mb-3">
    <b-row>
      <b-col>
        <h2>{{ item.attributes.libelle }}</h2>
      </b-col>
    </b-row>
    <b-row>
      <b-col v-for="item in items" :key="item.id" cols="auto">
        <StrapiContactComponent :item="item"> </StrapiContactComponent>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import StrapiContactComponent from "./StrapiContactComponent.vue";
import StrapiService from "../../services/strapi.service";
import UtilsService from "../../services/utils.service";
export default {
  name: "StrapiEquipeComponent",
  props: ["item"],
  components: {
    StrapiContactComponent,
  },
  data() {
    return {
      items: [],
    };
  },
  mounted() {
    return StrapiService.getContacts(this.item.id).then((response) => {
      this.items = response.data.data.sort(
        UtilsService.sortByNumberProperty("attributes.ordre")
      );
    });
  },
};
</script>
