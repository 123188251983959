<template>
  <b-modal ref="modal" scrollable @close="cancel">
    <template #modal-title>{{ title }}</template>
    <slot name="top">
      <WarningBloc>
        La taille du fichier est actuellement limit&eacute;e &agrave; 12 Mo.
      </WarningBloc>
    </slot>
    <b-form>
      <b-form-group
        :id="`group-fichier-${id}`"
        label="Fichier"
        :label-for="`input-fichier-${id}`"
      >
        <b-form-file
          :id="`input-fichier-${id}`"
          :name="`input-fichier-${id}`"
          placeholder="Choisissez ou déposez un fichier ici"
          browse-text="Parcourir..."
          accept=".gif,.jpg,.jpeg,.png,.pdf"
          v-model="upload.fichier"
        ></b-form-file>
      </b-form-group>
      <InputCommentaireComponent
        :showIfEmpty="true"
        v-model="upload.commentaire"
      ></InputCommentaireComponent>
    </b-form>
    <slot name="bottom"></slot>
    <template #modal-footer>
      <b-container fluid>
        <b-row align-h="between">
          <b-col>
            <b-button block pill variant="outline-primary" @click="cancel">
              Annuler
            </b-button>
          </b-col>
          <b-col>
            <b-button block pill variant="primary" @click="validate">
              Enregistrer
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </template>
  </b-modal>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import WarningBloc from "../blocs/WarningBloc.vue";
import InputCommentaireComponent from "../inputs/InputCommentaireComponent.vue";
export default {
  name: "FileUploadDialog",
  components: { WarningBloc, InputCommentaireComponent },
  props: {
    title: {
      type: String,
      default: "Nouveau fichier",
    },
  },
  data: () => ({
    id: uuidv4(),
    upload: {
      fichier: null,
      commentaire: null,
    },
    resolvePromise: null,
    rejectPromise: null,
  }),
  methods: {
    show() {
      this.upload.fichier = null;
      this.upload.commentaire = null;
      this.$refs["modal"].show();
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
    validate() {
      this.$refs["modal"].hide();
      this.resolvePromise(this.upload);
    },
    cancel() {
      this.$refs["modal"].hide();
      this.resolvePromise(null);
    },
  },
};
</script>
