<template>
  <section>
    <b-row>
      <!-- Layout grid -->
      <template v-if="collection.layout === 'grid'">
        <b-col v-for="item in items" :key="item.id" :cols="collection.cols">
          <component :is="collection.component" :item="item"></component>
        </b-col>
      </template>
      <!-- Layout float -->
      <template v-if="collection.layout === 'float'">
        <b-col>
          <div class="clearfix">
            <div class="float-left" v-for="item in items" :key="item.id">
              <component :is="collection.component" :item="item"></component>
            </div>
          </div>
        </b-col>
      </template>
      <!-- Layout sur mesure pour les cas particuliers -->
      <template v-if="collection.layout === 'unique'">
        <component :is="collection.component" :items="items"></component>
      </template>
    </b-row>
  </section>
</template>

<script>
import StrapiActualiteCard from "./StrapiActualiteCard.vue";
import StrapiAgendaComponent from "./StrapiAgendaComponent.vue";
import StrapiAssembleeGeneraleComponent from "./StrapiAssembleeGeneraleComponent.vue";
import StrapiCatalogueComponent from "./StrapiCatalogueComponent.vue";
import StrapiCategorieQuestionComponent from "./StrapiCategorieQuestionComponent.vue";
import StrapiEquipeComponent from "./StrapiEquipeComponent.vue";
import StrapiReglementCampagneComponent from "./StrapiReglementCampagneComponent.vue";
import StrapiService from "../../services/strapi.service";
export default {
  name: "StrapiCollectionComponent",
  props: ["collection", "societe"],
  components: {
    StrapiActualiteCard,
    StrapiAgendaComponent,
    StrapiAssembleeGeneraleComponent,
    StrapiCatalogueComponent,
    StrapiCategorieQuestionComponent,
    StrapiEquipeComponent,
    StrapiReglementCampagneComponent,
  },
  data() {
    return {
      items: [],
    };
  },
  computed: {
    isSalarie() {
      return this.$store.getters["auth/isSalarie"];
    },
  },
  async mounted() {
    let response = await StrapiService.getCollection(
      this.collection,
      this.societe.code
    );
    let items = response.data.data;
    if (!this.isSalarie) {
      items = items.filter((i) => !!i.attributes.publishedAt);
    }
    if (this.collection.sort) {
      items = items.sort(this.collection.sort);
    }
    this.items = items;
  },
};
</script>
