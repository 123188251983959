<template>
  <section class="mb-3 mb-md-4">
    <h3>{{ titre }}</h3>
    <b-row>
      <b-col class="mb-3 mr-0 mb-md-0 mr-md-3" cols="12" md="auto">
        <b-link target="_blank" :href="reglementHref">
          <b-img
            class="couverture"
            fluid
            :src="couvertureSrc"
            :alt="couvertureAlt"
          ></b-img>
        </b-link>
      </b-col>
      <b-col>
        <div>
          <StrapiMarkdownComponent
            :item="item.attributes.description"
          ></StrapiMarkdownComponent>
        </div>
        <div class="mt-3">
          <b-link target="_blank" :href="reglementHref">
            <b-button pill variant="primary">
              T&eacute;l&eacute;charger le r&egrave;glement
            </b-button>
          </b-link>
        </div>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import StrapiMarkdownComponent from "./StrapiMarkdownComponent.vue";
import StrapiService from "../../services/strapi.service";
export default {
  name: "StrapiReglementCampagneComponent",
  props: ["item"],
  components: { StrapiMarkdownComponent },
  computed: {
    titre() {
      return (
        this.item.attributes.titre ||
        `Règlement de campagne ${this.item.attributes.campagne}`
      );
    },
    couvertureSrc() {
      return StrapiService.getImageUrl(
        this.item.attributes.couverture,
        "small"
      );
    },
    couvertureAlt() {
      return (
        this.item.attributes.couverture?.data?.attributes.alternativeText || ""
      );
    },
    reglementHref() {
      return StrapiService.getAssetUrl(
        this.item.attributes.fichier?.data?.attributes.url
      );
    },
  },
};
</script>
