<template>
  <span>
    {{ computedValue }}
  </span>
</template>

<script>
export default {
  name: "DateTimeComponent",
  /**
   * value = valeur à afficher
   * locale = locale pour le formattage (fr-FR par défaut)
   */
  props: ["value", "locale", "date", "time"],
  computed: {
    dateMode() {
      return this.date === true || this.date === "true";
    },
    timeMode() {
      return this.time === true || this.time === "true";
    },
    sanitizedDate() {
      return typeof this.value === "object" ? this.value : new Date(this.value);
    },
    sanitizedLocale() {
      return this.locale ?? "fr-FR";
    },
    computedValue() {
      if (!this.sanitizedDate) {
        return "";
      }
      if (this.dateMode && !this.timeMode) {
        return this.sanitizedDate.toLocaleDateString(this.sanitizedLocale);
      }
      if (!this.dateMode && this.timeMode) {
        return this.sanitizedDate.toLocaleTimeString(this.sanitizedLocale);
      }
      return this.sanitizedDate.toLocaleString(this.sanitizedLocale);
    },
  },
};
</script>
