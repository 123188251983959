<template>
  <section>
    <template v-if="computedReadOnly">
      <b-form-group v-if="value" :label="label">
        <span class="commentaire">{{ value }}</span>
      </b-form-group>
    </template>
    <b-form-group
      v-else-if="computedShow || show"
      :id="`group-${id}`"
      :label="label"
      label-class="col-form-label"
      :label-for="`input-${id}`"
      :description="computedDescription"
    >
      <slot></slot>
      <b-form-textarea
        :id="`input-${id}`"
        :name="`input-${id}`"
        :rows="rows"
        :disabled="computedDisabled"
        :formatter="computedFormatter"
        :value="value"
        @input="(value) => $emit('input', value)"
      ></b-form-textarea>
    </b-form-group>
    <b-form-group
      v-else
      :label="label"
      :label-cols="computedLabelCols['xs']"
      :label-cols-sm="computedLabelCols['sm']"
      :label-cols-md="computedLabelCols['md']"
      :label-cols-lg="computedLabelCols['lg']"
      :label-cols-xl="computedLabelCols['xl']"
    >
      <span class="labeled commentaire">
        <b-link href="#" @click="show = true">
          <b-icon-plus variant="primary"></b-icon-plus>
          Ajouter
        </b-link>
      </span>
    </b-form-group>
  </section>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import { computed as inputsComputed } from "../../services/inputs.service";
export default {
  name: "InputCommentaireComponent",
  props: {
    // valeur du commentaire
    value: String,
    // propriété label du form group
    label: {
      type: String,
      default: "Commentaire",
    },
    // propriété label-cols du form group
    labelCols: {
      type: [String, Number, Object],
      default: "auto",
    },
    // nombre de lignes du textarea
    rows: {
      type: Number,
      default: 3,
    },
    // nombre de caractères autorisés
    size: {
      type: Number,
      default: 200,
    },
    // propriété description du form group
    description: String,
    // affichage de la textarea même si vide
    showIfEmpty: [Boolean, String],
    // affichage lecture seule (texte brut sans textarea)
    readOnly: [Boolean, String],
    // propriété disabled du controle
    disabled: [Boolean, String],
    // fonction de formattage du textarea
    formatter: undefined,
  },
  data: () => ({
    id: `commentaire-${uuidv4()}`,
    show: false,
  }),
  computed: {
    ...inputsComputed,
    computedDescription() {
      return (
        this.description ??
        `${this.value?.length ?? 0}/${this.size} caractères autorisés`
      );
    },
    computedShow() {
      return (
        this.value || this.showIfEmpty === true || this.showIfEmpty === "true"
      );
    },
    computedReadOnly() {
      return this.readOnly === true || this.readOnly === "true";
    },
    computedFormatter() {
      return this.formatter ?? this.methodFormatter;
    },
  },
  mounted() {
    // Si valeur non nulle au montage, bloquer ouvert
    this.show = !!this.value;
  },
  methods: {
    methodFormatter(value) {
      return value?.substring(0, this.size) ?? "";
    },
  },
};
</script>
